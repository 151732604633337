<template>
  <div>
    <div class="alert alert-primary d-flex my-3" role="alert">
                    <div class="mr-2">
                        <i class="bi bi-info-circle-fill mr-1"></i>
                    </div>
                    <div>
                        <p class="mb-1">
                          Additional user roles and project permissions can be defined and assigned to individuals within your organization. 
                        </p>
                        <p>
                         This allows for precise control over which users are authorized to make specific changes to GIRI, manage projects, and modify instructions.
                        </p>
                        <p class="mb-0">
                          If you see this message, the roles and permissions feature is disabled for your organization. In case you are interested in that functionality, please 
                            <a href="https://www.ar-giri.com/start" target="_blank">
                              schedule an appointment with our service team!
                            </a>
                        </p>
                    </div>
                </div>    
    <div class="d-flex">
      <h2 class="title my-3">Configure Roles</h2>
    </div>
    <div class="d-flex">
      <b-row class="col-lg-12 mb-4 mb-md-4">
        <b-input-group class="col-lg-12 mb-4 mb-md-4">
          <b-form-input type="search" placeholder="Search by permission" v-model="search" />
          <b-input-group-append>
            <b-input-group-text>
              <i class="bi bi-search"></i>
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </b-row>
    </div>

    <b-alert :show="dismissCountDown" dismissible variant="success" @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged">
      {{ alertMsgContent }}
    </b-alert>
    <b-alert :show="dismissCountDown2" dismissible variant="success" @dismissed="dismissCountDown2 = 0"
      @dismiss-count-down="countDownChanged">
      {{ alertMsgContent }}
    </b-alert>

    <div class="table-container mb-3" v-if="roles.length > 0">
      <b-table :key="tableKey" class="mb-3" :items="permissionsFiltered" :fields="fields" :per-page="perPage"
        :current-page="currentPage" striped bordered hover>

        <template v-slot:head()="data">
          <div v-if="data.label != 'Permission'" class="d-flex flex-column align-items-center">
            <span>{{ data.label }}</span>
            <!-- check data structure -->
            <!-- <pre>{{ data }}</pre>  -->
            <div class="d-flex">
              <b-button variant="link" @click="openEditModal(data)" :disabled="true">
                <i class="bi bi-pencil-square"></i>
              </b-button>
              <b-button variant="link" @click="openDeleteModal(data)" :disabled="true">
                <i class="bi bi-trash text-danger"></i>
              </b-button>
            </div>
          </div>
        </template>

        <template v-for="role in roles" v-slot:[`cell(${role.organizationRoleId})`]="row">
          <div class="d-flex flex-column align-items-center" :key="role.organizationRoleId">
            <b-form-checkbox class="d-flex justify-content-around"
              :checked="checkPermission(row.field.key, row.item.key)"
              @change="handlePermissionChange($event, row.field.key, row.item.key)" 
              :disabled="true"/>
          </div>
        </template>
      </b-table>
    </div>
    
    <div v-else class="text-center my-3">
      <b-alert show variant="info">
        <p>No roles created yet. Please create a new role to configure permissions.</p>
      </b-alert>
    </div>

    <div class="d-flex justify-content-end">
      <b-button class="mb-5" variant="outline-danger" :disabled="true" @click="revertPermissions">Revert
        Changes</b-button>
      <b-button class="mb-5 mx-3" :disabled="true" @click="savePermissions"
        style="background-color: #0093FF">Save Permissions</b-button>
    </div>

    <!-- Edit Role Modal -->
    <b-modal v-model="editModal" title="Edit Role Name">
      <input type="role" class="form-control" id="editRoleInput" v-model="selectedRoleName" maxlength="30">
      <div slot="modal-footer" class="text-right">
        <b-button class="mx-2" variant="secondary" @click="closeEditModal">Cancel</b-button>
        <b-button class="mx-2" variant="primary" @click="editRoleName">Save</b-button>
      </div>
    </b-modal>

    <!-- Delete Role Confirmation Modal -->
    <b-modal v-model="deleteModal" title="Delete Role?">
      <p>Are you sure you want to delete role <b>“{{ selectedRoleName }}”</b>?</p>
      <p>Users assigned this role will be demoted to 'viewer' and might lose their current permissions.</p>
      <div slot="modal-footer" class="text-right">
        <b-button class="mx-2" variant="secondary" @click="closeDeleteModal">Cancel</b-button>
        <b-button class="mx-2" variant="danger" @click="confirmDeleteRole">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RolesTableNoEdit',
  props: {
    organisationId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      roles: [],
      permissions: [],
      search: '',
      menu: '',
      alertMsgContent: "",
      dismissCountDown: 0,
      infoModal: false,
      permissionsChanged: false,
      role: '',
      editModal: false,
      selectedRoleName: '',
      selectedRoleId: '',
      deleteModal: false,
      roleToDelete: null,
      selectedPermissions: {},
      originalPermissions: {},
      tableKey: 0,
    };
  },
  computed: {
    permissionsFiltered() {
      if (!this.search.trim()) {
        return this.permissions;
      }
      const searchTerm = this.search.trim().toLowerCase();
      return this.permissions.filter(permission =>
        permission.key.toLowerCase().includes(searchTerm)
      );
    },
    fields() {
      const fields = [{ key: 'key', label: 'Permission' }];
      this.roles.forEach(role => {
        fields.push({ key: role.organizationRoleId, label: role.name });
      });
      return fields;
    },
    rows() {
      return this.permissionsFiltered.length;
    },
  },
  mounted() {
    this.getPermissions().then(() => {
      this.getRoles();
    });
  },
  methods: {
    savePermissions() {
      const promises = [];
      Object.keys(this.selectedPermissions).forEach(roleId => {
        const permissionsArray = Object.keys(this.selectedPermissions[roleId]).filter(key => this.selectedPermissions[roleId][key]);
        const promise = axios.post(`${this.$address}api/organizationRoles/editRole?access_token=${this.token}`, {
          permissionsArray,
          roleId,
        });
        promises.push(promise);
      });

      // Execute all promises
      Promise.all(promises)
        .then(responses => {
          this.alertMsgContent = "The changes have been saved successfully.";
          this.dismissCountDown = 5;
          this.permissionsChanged = false;
          this.originalPermissions = JSON.parse(JSON.stringify(this.selectedPermissions));;
        })
        .catch(error => {
          console.error('Error saving permissions:', error);
        });
    },
    revertPermissions() {
      this.selectedPermissions = JSON.parse(JSON.stringify(this.originalPermissions));
      this.permissionsChanged = false;
      this.tableKey++;
    },
    handlePermissionChange(event, roleId, permissionKey) {
      if (!this.selectedPermissions[roleId]) {
        this.$set(this.selectedPermissions, roleId, {});
      }

      const isChecked = event;

      if (isChecked) {
        this.$set(this.selectedPermissions[roleId], permissionKey, true);
      } else {
        this.$delete(this.selectedPermissions[roleId], permissionKey);
      }

      this.permissionsChanged = true;
    },
    createNewRole() {
      if (this.role.trim() === '') {
        // role validation
        return;
      }
      const requestData = {
        name: this.role,
        permissionsArray: [],
        groupID: this.organisationId,
      };

      axios.post(this.$address + 'api/organizationRoles/createNewRole?access_token=' + this.token, requestData)
        .then(response => {
          this.role = '';
          this.alertMsgContent = "The role has been successfully created";
          this.dismissCountDown = 5;
          this.getRoles();
        })
        .catch(error => {
          console.error('Error creating role:', error);
        });
    },
    getRoles() {
      axios.get(this.$address + 'api/organizationRoles/getRoles?access_token=' + this.token, {
        params: {
          organizationId: this.organisationId
        },
      })
        .then(response => {
          this.roles = response.data;
          console.log(this.roles)
          this.roles.forEach(role => {
            this.$set(this.selectedPermissions, role.organizationRoleId, {});
            this.$set(this.originalPermissions, role.organizationRoleId, {});
            console.log(role)
            const permissionValue = BigInt(role.permissionValue);

            this.permissions.forEach((permission, index) => {
              const permissionMask = BigInt(1) << BigInt(index);
              if ((permissionValue & permissionMask) !== BigInt(0)) {
                this.$set(this.selectedPermissions[role.organizationRoleId], permission.key, true);
                this.$set(this.originalPermissions[role.organizationRoleId], permission.key, true);
              }
            });
          });
        })
        .catch(error => {
          console.error('Error fetching roles:', error);
        });
    },
    getPermissions() {
      return axios.get(this.$address + 'api/organizationRoles/getPermissions?access_token=' + this.token)
        .then(response => {
          this.permissions = Object.keys(response.data.permissions).map(key => ({
            key: key,
          }));
        })
        .catch(error => {
          console.error('Error fetching permissions:', error);
        });
    },
    checkPermission(roleId, permissionKey) {
      const role = this.roles.find(r => r.organizationRoleId === roleId);
      if (!role) return false;

      const permissionValue = BigInt(role.permissionValue);

      const permissionIndex = this.permissions.findIndex(p => p.key === permissionKey);
      if (permissionIndex === -1) {
        return false;
      }

      const permissionMask = BigInt(1) << BigInt(permissionIndex);

      const hasPermission = (permissionValue & permissionMask) !== BigInt(0);

      return hasPermission;
    },
    openEditModal(data) {
      this.selectedRoleName = data.label;
      this.selectedRoleId = data.column;
      this.editModal = true;
    },
    closeEditModal() {
      this.selectedRoleName = '';
      this.selectedRoleId = '';
      this.editModal = false;
    },
    editRoleName() {

      axios.post(this.$address + 'api/organizationRoles/editRoleName?access_token=' + this.token,{
          roleId: this.selectedRoleId,
          name: this.selectedRoleName,
        },
      )
        .then(response => {
          this.role = '';
          this.alertMsgContent = "The role name has been successfully edited";
          this.dismissCountDown = 5;
          this.getRoles();
        })
        .catch(error => {
          console.error('Error editing role name:', error);
        });
      this.closeEditModal();
    },
    openDeleteModal(data) {
      this.selectedRoleName = data.label;
      this.selectedRoleId = data.column;
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.selectedRoleName = '';
      this.selectedRoleId = '';
      this.deleteModal = false;
    },
    confirmDeleteRole() {
      axios.delete(`${this.$address}api/organizationRoles/${this.selectedRoleId}?access_token=${this.token}`
      )
        .then(response => {
          this.alertMsgContent = "The role has been successfully deleted";
          this.dismissCountDown = 5;
          this.getRoles();
        })
        .catch(error => {
          console.error('Error deleting role:', error);
        });
      this.closeDeleteModal();
    },
  },
};
</script>

<style scoped>
h2.title {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
}

::v-deep .table>tbody>tr>td {
  vertical-align: middle !important;
}

::v-deep .table thead th {
  vertical-align: middle !important;
}
</style>
