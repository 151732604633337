<!-- USER LOGIN & REGISTRATION SECTION -->
<template>
    <div class="container my-3">  
        <div class="mx-auto">
            <p align="center">
                <img  id="logo" v-bind:src="this.$brandSettings.logoImage" width="40%" style="display:block"/>
            </p>
        </div>

        <div v-if="!loged">
            <loginSection @getIdToken="updateUserID" v-on:SetUpUsertype="SetUpUsertype($event)" v-on:changeTokenValue="TokenHasChanged($event)" ></loginSection>
            <!--<AdminTools :isLoggedIn="loggedin" :usertype="usertype" :accesstoken="token" :orgs="orgs"/>-->
        </div>
        <div v-else>
            <div class="container">
                <div class="row">
                    <div class="col-2">
                        <img  id="logo" v-bind:src="this.$brandSettings.logoImage" width="100%" style="display:block"/>
                    </div>
                </div>
            </div>
                

            <Menu
                :userType="usertype"
                :token="token"
                @ChangeMenu="nextMenu($event)"
                :address="address"
                :email="email"
                :analyticsStatus="orgs[0].analyticsStatus"
            />
                
            <div v-if="this.menuTab === 'AdminTools' && this.customRoles">
                <UsersManagerRoles :token="token" :usertype="usertype" :organisationID="organisationID"/>
            </div>
            <div v-else-if="this.menuTab === 'AdminTools'">
                <UsersManager :token="token" :usertype="usertype" :organisationID="organisationID"/>
            </div>
            <div v-if="(this.menuTab === 'OrgaTools') && (usertype === 'admin')">
                <OrganizationManager :accesstoken="token" />
                <!-- <OrgFilesDownload :isLoggedIn="loggedin" :usertype="usertype" :accesstoken="token" :orgs="orgs"/> -->
            </div>
            
            <div v-show="(this.menuTab === 'ContentTools')">
                <ContentManagement  :token="token" :Organization="organisationID"/>
            </div>

            <template v-if="(this.menuTab === 'Customize')">
                <Customization
                    :organization="organisationID"
                    :token="token"
                    :usertype="usertype"
                    :whiteLabelStatus="orgs[0].whiteLabelStatus"
                    :passwordProtectionStatus="orgs[0].passwordProtectionStatus"
                />
            </template>

            <template v-if="(this.menuTab === 'Workplaces')">
                <Workplaces
                    :organisationId="organisationID"
                    :token="token"
                    :usertype="usertype"
                />
            </template> 

            <template v-if="(this.menuTab === 'Analytics')">
                <Analytics
                    :jobDoneStatus="orgs[0].jobDoneStatus"
                    :organisationId="organisationID"
                    :token="token"
                    :usertype="usertype"
                />
            </template>

            <div v-show="(this.menuTab === 'WfPasswordmanager')">
                <WfPasswordmanager :usertype="usertype" :token="token" :organisationId="organisationID"/>
            </div>

            <template v-if="this.menuTab === 'Groups' && (orgs[0].visibilityGroups || usertype === 'admin')">
                <GroupsManager :token="token" :usertype="usertype" :organisationID="organisationID" :userID="id" />
            </template>
            <template v-if="this.menuTab === 'Groups' && !orgs[0].visibilityGroups && usertype!=='admin'">
                <div class="alert alert-primary d-flex my-3" role="alert">
                    <div class="mr-2">
                        <i class="bi bi-info-circle-fill mr-1"></i>
                    </div>
                    <div>
                        <p class="mb-1">
                            Configuring visibility groups lets you decide which user sees which projects.
                        </p>
                        <p>
                            This can be especially useful if your organization uses a lot of projects or 
                            has security-critical projects.
                        </p>
                        <p class="mb-0">
                            If you are interested in this functionality, please 
                            <a href="https://www.ar-giri.com/giri-work-instructions-sales" target="_blank">
                                schedule an appointment with our service team!
                            </a>
                        </p>
                    </div>
                </div>
            </template>

            <template v-if="this.menuTab === 'demoTools' && (usertype === 'admin')">
                <DemoAccountsmanager :token="token" :usertype="usertype" />
            </template>
            <template v-if="this.menuTab === 'Roles'">
                <RolesManager v-if="customRoles"
                    :organisationId="organisationID"
                    :token="token"
                />
                <RolesManagerNoEdit v-else
                :organisationId="organisationID"
                :token="token"
                >

                </RolesManagerNoEdit>
            </template>
        </div>
    </div> 
</template>

<script>
import axios from 'axios'
import login from '@/components/Login.vue'
import StackModal from '@innologica/vue-stackable-modal'
import OrgFilesDownload from '@/components/AdminTools/OrgFilesDownload.vue'
import UsersManager from '@/components/Tools/UsersManager.vue'
import UsersManagerRoles from '@/components/Tools/UsersManagerRoles.vue'
import Menu from '@/components/Tools/Menu.vue'
import Customization from '@/components/Menus/customization.vue'
import ContentManagement from '@/components/Menus/ContentManagement.vue'
import WfPasswordmanager from '@/components/AdminTools/WfPasswordmanager.vue'
import GroupsManager from './Tools/GroupsManager.vue';
import RolesManager from './Roles/RolesManager.vue'
import RolesManagerNoEdit from './Roles/RolesManagerNoEdit.vue'

    export default{
        components: {
            'ContentManagement': ContentManagement,
            'loginSection': login,
            'OrgFilesDownload':OrgFilesDownload,
            'UsersManager':UsersManager,
            'UsersManagerRoles':UsersManagerRoles,
            'Menu':Menu,
            'Customization':Customization,
            OrganizationManager: () => import('@/components/AdminTools/OrganizationManager'),
            'WfPasswordmanager':WfPasswordmanager,
            Workplaces: () => import('@/components/Workplaces/Workplaces'),
            Analytics: () => import('@/components/Analytics/Analytics'),
            'GroupsManager': GroupsManager,
            DemoAccountsmanager: () => import('./Tools/DemoAccountsmanager'),
            'RolesManager':RolesManager,
            'RolesManagerNoEdit':RolesManagerNoEdit,
        },
        props:['msg'],
  
                data(){
            return{
                address:"to be specified", //loopback access point
                loggedin:false,
                name:"",
                description:"",
                username:"",
                password:"",
                email:"t@t.t",
                organisation:"",
                usertype:"",
                token:"",
                customRoles:false,
                fields:[{username:'username'}, {usertype:'usertype'}, {email:'email'},{organisationID:'organisationID'}, {password:'password'},{action:'action'}],
                organisationID:"",
                orgs:[{id:'id',name:'name'}],
                loged:false,
                menuTab: 'AdminTools',
                id: '',
            }
        },
        beforeMount(){
            const expression = "code=[^&^=]*";
            if(window.location.href.search(expression)>-1){
                const code = window.location.href.match(expression).pop();
                const token = code.split("=")[1];
                const domain=self.location.host;
                const url = domain =="localhost" || domain=="giri" || domain=="127.0.0.1"?
                "https://localhost:3000/":
                "https://"+self.location.host+"/";
                this.address = url;
                
                axios.post(url+"api/auth0s/validateAuth0Token",{"token":token}).then(
                    (response)=>{
                        const resp = response.data.accessToken;
                        const expre = "^[A-Za-z0-9]+$";
                        if((resp.id && resp.userId)?
                            resp.id.match(expre) && resp.userId.match(expre) && resp.id.length > 0 && resp.userId.length > 0:
                            false
                        ){
                            console.log(resp.id);
                            this.token = resp.id;
                            axios.get(this.address+'api/UserAccounts/'+resp.userId+'?access_token='+this.token).then(response2=>{
                                if(response2.data.usertype=="admin"|| response2.data.usertype=="manager" || response2.data.usertype=="orgAdmin" || response2.data.usertype=="demoAdmin"){
                                    this.userId = resp.userId
                                    this.SetUpUsertype(response2.data)
                                    this.updateUserID(resp.userId);                                
                                    window.history.pushState("","","/");
                                    this.loged = true;
                                }else{
                                    console.log("access denied for werker")
                                }
                            })
                        }
                    },
                    (err)=>{
                        console.log(err);
                    }
                );
            }
        },
        mounted(){
            /*var domain=self.location.host;
            if(domain =="localhost" || domain=="giri" || domain=="127.0.0.1"){
                console.log("On premise server");
                this.address="https://localhost:3000/";
            }else{
                console.log("Cloud server");
                this.address="https://"+self.location.host+":3000/"
            }*/
        },   
        methods:{
            SetUpUsertype(data){
                this.usertype=data.usertype
                this.email=data.email
                this.organisationID=data.organisationID

                if(this.usertype=="admin"){
                    axios.get(this.$address+"api/Organisations?access_token="+this.token)
                        .then(response => {
                            this.orgs=response.data
                            console.log(this.orgs)
                        })
                }else{ //for manager
                    axios.get(this.$address+"api/UserAccounts/"+data.id+"/organisation?access_token="+this.token)
                        .then(response => {
                            this.orgs=[response.data]
                            if(this.orgs[0]){
                                this.customRoles=this.orgs[0].customRoles
                            }
                            console.log(this.orgs)
                            console.log(this.customRoles)
                        })
                }


                if(this.usertype==="admin"){
                    axios.get(this.$address+"api/UserAccounts?access_token="+this.token)
                        .then(response => {
                            this.fields=response.data
                        })
                }else{
                    this.LoadDataFromDomainOnly()
                }
                //document.getElementById("tablelist").style.display = "block"
                document.getElementById("logo").style.display = "none"
                this.loged = true;

            },
            LoadDataFromDomainOnly(){
                console.log(this.organization)
                axios.get(this.$address+"api/Organisations/"+this.organisationID+"/userAccounts?&access_token="+this.token)
                        .then(response => {
                            this.fields=response.data
                        })
            },
            TokenHasChanged(newToken){
                this.token=newToken
            },
            nextMenu(nxt){
                console.log(nxt);
                this.menuTab = nxt;
            },
            updateUserID(userID){
                this.id = userID;
            }
        }
        
    }
</script>
<style lang="css" scoped>
.safari-warning{
    border: 1.5px solid red;
    padding: 15px 20px;
    width: 50%;
    margin: auto ;
    position: relative;
    font-size: 15px;
}
</style>
