<template>
  <div>
    <RolesMenu v-on:ChangeRolMenu="changeTab($event)"></RolesMenu>
    <div v-if="menu === 'RolesTableNoEdit'">
      <RolesTableNoEdit
        :organisationId="organisationId"
        :token="token"
      />
    </div>
  </div>
</template>

<script>
import RolesMenu from '../Menus/RolesMenu.vue';
import RolesTable from './RolesTable.vue';
import RolesTableNoEdit from './RolesTableNoEdit.vue';
export default {
  name: 'RolesManagerNoEdit',
  components: {
    'RolesMenu': RolesMenu,
    'RolesTable': RolesTable,
    'RolesTableNoEdit':RolesTableNoEdit
  },
  props: {
    organisationId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menu: "RolesTableNoEdit",
    };
  },
  methods: {
    changeTab(nxt) {
      this.menu = nxt;
      console.log(nxt);
    },
  }
};
</script>

<style scoped>
h2.title {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
}
</style>