<template>
  <div>
    <div class="d-flex">
      <h2 class="title my-3">Configure Roles</h2>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <b-input-group class="w-50">
        <b-form-input type="search" placeholder="Search by permission" v-model="search" />
        <b-input-group-append>
          <b-input-group-text>
            <i class="bi bi-search"></i>
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
      <b-button style="background-color: #0093FF;" @click="showCreateRoleModal = true">Create new role</b-button>
    </div>

    <b-alert :show="dismissCountDown" variant="success" @dismiss-count-down="countDownChanged">
      {{ alertMsgContent }}
    </b-alert>
    <b-alert :show="dismissCountDown2" variant="success" @dismiss-count-down="countDownChanged">
      {{ alertMsgContent }}
    </b-alert>

    <div class="table-container mb-3" v-if="roles.length > 0">
      <b-table sticky-header="35em" :key="tableKey" class="mb-3" :items="permissionsFiltered" :fields="fields" :per-page="perPage"
        :current-page="currentPage" striped bordered hover>

        <template v-slot:head()="data">
          <div v-if="data.label != 'Permission'" class="d-flex flex-column align-items-center">
            <span>{{ data.label }}</span>
            <!-- check data structure -->
            <!-- <pre>{{ data }}</pre>  -->
            <div class="d-flex" v-if="!data.field.default">
              <b-button variant="link" @click="openEditModal(data)">
                <i class="bi bi-pencil-square"></i>
              </b-button>
              <b-button v-if="!data.field.default" variant="link" @click="openDeleteModal(data)">
                <i class="bi bi-trash text-danger"></i>
              </b-button>
            </div>
          </div>
        </template>

        <template v-for="role in roles" v-slot:[`cell(${role.organizationRoleId})`]="row">
          <div class="d-flex flex-column align-items-center" :key="role.organizationRoleId">
            <b-form-checkbox class="d-flex justify-content-around " :disabled="role.default"
              :checked="checkPermission(row.field.key, row.item.key)"
              @change="handlePermissionChange($event, row.field.key, row.item.key)" />
          </div>
        </template>
      </b-table>
    </div>
    
    <div v-else class="text-center my-3">
      <b-alert show variant="info">
        <p>No roles created yet. Please create a new role to configure permissions.</p>
      </b-alert>
    </div>

    <div class="d-flex justify-content-end">
      <b-button class="mb-5" variant="outline-danger" :disabled="!permissionsChanged" @click="revertPermissions">Revert
        Changes</b-button>
      <b-button class="mb-5 mx-3" :disabled="!permissionsChanged" @click="savePermissions"
        style="background-color: #0093FF">Save Permissions</b-button>
    </div>

    <!-- Edit Role Modal -->
    <b-modal v-model="editModal" title="Edit Role Name">
      <input type="role" class="form-control" id="editRoleInput" v-model="selectedRoleName" maxlength="30">
      <div slot="modal-footer" class="text-right">
        <b-button class="mx-2" variant="secondary" @click="closeEditModal">Cancel</b-button>
        <b-button class="mx-2" variant="primary" @click="editRoleName">Save</b-button>
      </div>
    </b-modal>

    <!-- Delete Role Confirmation Modal -->
    <b-modal v-model="deleteModal" title="Delete Role?">
      <p>Are you sure you want to delete role <b>{{ selectedRoleName }}</b>?</p>
      <p>Users assigned this role will be demoted to 'viewer' and might lose their current permissions.</p>
      <div slot="modal-footer" class="text-right">
        <b-button class="mx-2" variant="secondary" @click="closeDeleteModal">Cancel</b-button>
        <b-button class="mx-2" variant="danger" @click="confirmDeleteRole">Delete</b-button>
      </div>
    </b-modal>
   <!-- Modal -->
   <b-modal
      v-model="showCreateRoleModal"
      title="Create new role"
      @hide="resetForm"
      hide-footer
    >
      <!-- Text input field -->
      <div class="form-group">
        <label for="newRoleName">Enter a name for the new role *</label>
        <input
          type="text"
          class="form-control"
          id="newRoleName"
          v-model="newRoleName"
          :class="{ 'is-invalid': newRoleError.length > 0 }"
        />
        <div class="invalid-feedback">{{ newRoleError }}</div>
      </div>

      <!-- Dropdown for copying permissions -->
      <div class="form-group">
        <label for="copyPermissions">Copy permissions from an existing role</label>
        <b-form-select
          id="copyPermissions"
          v-model="selectedRoleToCopy"
          :options="[{ value: 0, text: 'No role' }, ...roles.map(role => ({ value: role.permissionValue, text: role.name }))]"
          class="form-control"
        ></b-form-select>
      </div>

      <!-- Instructional text -->
      <p>For further adjustments of other permissions use the check-boxes in the table.</p>

      <!-- Buttons aligned to the right -->
      <div class="d-flex justify-content-end mt-3">
        <b-button variant="secondary" @click="showCreateRoleModal = false">Cancel</b-button>
        <b-button variant="primary" class="ml-2" @click="createNewRole">Create</b-button>
      </div>
    </b-modal>    
  </div>
</template>


<script>
import axios from 'axios';
import { BIconTelephonePlusFill } from 'bootstrap-vue';

export default {
  name: 'RolesTable',
  props: {
    organisationId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      roles: [],
      permissions: [],
      search: '',
      menu: '',
      alertMsgContent: "",
      dismissCountDown: 0,
      infoModal: false,
      permissionsChanged: false,
      role: '',
      editModal: false,
      selectedRoleName: '',
      selectedRoleId: '',
      deleteModal: false,
      roleToDelete: null,
      selectedPermissions: {},
      originalPermissions: {},
      lastSavedPermissions: {},
      tableKey: 0,
      newRoleName:'',
      showCreateRoleModal: false,
      roleOptions: "",
      selectedRoleToCopy: null,
      newRoleError:""
    };
  },
  computed: {
    permissionsFiltered() {
      if (!this.search.trim()) {
        return this.permissions;
      }
      const searchTerm = this.search.trim().toLowerCase();
      return this.permissions.filter(permission =>
      permission.key.toLowerCase().includes(searchTerm)
      );
    },
    fields() {
      const fields = [{ key: 'key', label: 'Permission',default:"default",stickyColumn:true,}];
      this.roles.forEach(role => {
        fields.push({ key: role.organizationRoleId, label: role.name,default:role.default });
      });
      return fields;
    },
    rows() {
      return this.permissionsFiltered.length;
    },
  },
  mounted() {
    this.getPermissions().then(() => {
      this.getRoles()
    });
  },
  methods: {
    savePermissions() {
      const promises = [];
      Object.keys(this.selectedPermissions).forEach(roleId => {
        const permissionsArray = Object.keys(this.selectedPermissions[roleId]).filter(key => this.selectedPermissions[roleId][key]);
        const promise = axios.post(`${this.$address}api/organizationRoles/editRole?access_token=${this.token}`, {
          permissionsArray,
          roleId,
        });
        promises.push(promise);
      });

      // Execute all promises
      Promise.all(promises)
        .then(responses => {
          this.alertMsgContent = "The changes have been saved successfully.";
          this.dismissCountDown = 5;
          this.permissionsChanged = false;
          // Update lastSavedPermissions with the current state
          this.lastSavedPermissions = JSON.parse(JSON.stringify(this.selectedPermissions));
          this.getRoles();
        })
        .catch(error => {
          console.error('Error saving permissions:', error);
        });
    },
    revertPermissions() {
      // Revert to the last saved state (lastSavedPermissions)
      this.selectedPermissions = JSON.parse(JSON.stringify(this.lastSavedPermissions));
      this.permissionsChanged = false;
      this.tableKey++;
    },
    handlePermissionChange(event, roleId, permissionKey) {
      if (!this.selectedPermissions[roleId]) {
        this.$set(this.selectedPermissions, roleId, {});
      }

      const isChecked = event;

      if (isChecked) {
        this.$set(this.selectedPermissions[roleId], permissionKey, true);
      } else {
        this.$delete(this.selectedPermissions[roleId], permissionKey);
      }

      this.permissionsChanged = !this.arePermissionsEqual(this.selectedPermissions, this.lastSavedPermissions);
    },
    arePermissionsEqual(permissions1, permissions2) {
      const roles1 = Object.keys(permissions1);
      const roles2 = Object.keys(permissions2);

      if (roles1.length !== roles2.length) return false;

      for (const roleId of roles1) {
        if (!permissions2[roleId]) return false;

        const perms1 = permissions1[roleId];
        const perms2 = permissions2[roleId];

        const keys1 = Object.keys(perms1);
        const keys2 = Object.keys(perms2);

        if (keys1.length !== keys2.length) return false;

        for (const key of keys1) {
          if (perms1[key] !== perms2[key]) return false;
        }
      }

      return true;
    },
    createNewRole() {
      if (this.newRoleName.trim() === '') {
        // role validation
        this.newRoleError ="Invalid role name"
        return;
      }
      const requestData = {
        name: this.newRoleName,
        permissionsArray: [],
        groupID: this.organisationId,
        value:this.selectedRoleToCopy.toString()
      };

      axios.post(this.$address + 'api/organizationRoles/createNewRole?access_token=' + this.token, requestData)
        .then(response => {
          this.newRoleName = '';
          this.alertMsgContent = "The role has been successfully created";
          this.dismissCountDown = 5;
          this.showCreateRoleModal = false;
          this.getRoles();
        })
        .catch(error => {
          console.error('Error creating role:', error);
        });
    },
    getRoles() {
      axios.get(this.$address + 'api/organizationRoles/getRoles?access_token=' + this.token, {
        params: {
          organizationId: this.organisationId
        },
      })
        .then(response => {
          this.roles = response.data;
          this.roles.forEach(role => {
            this.$set(this.selectedPermissions, role.organizationRoleId, {});
            this.$set(this.lastSavedPermissions, role.organizationRoleId, {});

            const permissionValue = BigInt(role.permissionValue);

            this.permissions.forEach((permission) => {
              if ((permissionValue & BigInt(permission.value)) !== BigInt(0)) {
                this.$set(this.selectedPermissions[role.organizationRoleId], permission.key, true);
                this.$set(this.lastSavedPermissions[role.organizationRoleId], permission.key, true);
              }
            });
          });
        })
        .catch(error => {
          console.error('Error fetching roles:', error);
        });
    },
    getPermissions() {
      return axios.get(this.$address + 'api/organizationRoles/getPermissions?access_token=' + this.token)
        .then(response => {
          this.permissions = Object.keys(response.data.permissions).map(key => ({
            key: key,
            value: response.data.permissions[key].value
          }));
        })
        .catch(error => {
          console.error('Error fetching permissions:', error);
        });
    },
    checkPermission(roleId, permissionKey) {
      const role = this.roles.find(r => r.organizationRoleId === roleId);
      if (!role) return false;

      const permissionValue = BigInt(role.permissionValue);
      const permissionIndex = this.permissions.find(p => p.key === permissionKey);
      if (permissionIndex === -1) {
        return false;
      }
      const hasPermission = (permissionValue & BigInt(permissionIndex.value))!== BigInt(0);
      console.log("The comparastion of " +role.permissionValue +" and "+ permissionIndex.value + " is "+ hasPermission)
      return hasPermission;
    },
    openEditModal(data) {
      this.selectedRoleName = data.label;
      this.selectedRoleId = data.column;
      this.editModal = true;
    },
    closeEditModal() {
      this.selectedRoleName = '';
      this.selectedRoleId = '';
      this.editModal = false;
    },
    editRoleName() {

      axios.post(this.$address + 'api/organizationRoles/editRoleName?access_token=' + this.token,{
          roleId: this.selectedRoleId,
          name: this.selectedRoleName,
        },
      )
        .then(response => {
          this.role = '';
          this.alertMsgContent = "The role name has been successfully edited";
          this.dismissCountDown = 5;
          this.getRoles();
        })
        .catch(error => {
          console.error('Error editing role name:', error);
        });
      this.closeEditModal();
    },
    openDeleteModal(data) {
      this.selectedRoleName = data.label;
      this.selectedRoleId = data.column;
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.selectedRoleName = '';
      this.selectedRoleId = '';
      this.deleteModal = false;
    },
    confirmDeleteRole() {
      axios.delete(`${this.$address}api/organizationRoles/${this.selectedRoleId}?access_token=${this.token}`
      )
        .then(response => {
          this.alertMsgContent = "The role has been successfully deleted";
          this.dismissCountDown = 5;
          this.getRoles();
        })
        .catch(error => {
          console.error('Error deleting role:', error);
        });
      this.closeDeleteModal();
    },
  },
};
</script>

<style scoped>
h2.title {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
}

::v-deep .table>tbody>tr>td {
  vertical-align: middle !important;
}

::v-deep .table thead th {
  vertical-align: middle !important;
}
</style>
