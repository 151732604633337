<template>
    <div>
        <b-navbar toggleable="lg" type="light" variant="light">
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item v-on:click="emitRolesMenuChange('RolesTable')">
                        Organisation
                    </b-nav-item>
                    <b-nav-item v-on:click="emitRolesMenuChange('GroupsRolesTable')">
                        Group
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    export default {
        props: {
            usertype: {
                type: String,
                required: true,
            }
        },
        methods:{
            emitRolesMenuChange(nextTab){
                this.$emit("ChangeRolMenu", nextTab);
            }
        },
    }
</script>
<style lang="css" scoped>

</style>