<template>
    <div>
        <b-navbar toggleable="lg" type="light" variant="light">
            <b-navbar-brand href="#">{{this.$brandSettings.brand}}</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item v-on:click="emitMenuChange('AdminTools')">Users</b-nav-item>
                <b-nav-item v-on:click="emitMenuChange('Roles')" v-if="(userType === 'orgAdmin')">Roles</b-nav-item>
                <b-nav-item v-if="(userType === 'orgAdmin' || userType === 'manager' || userType === 'admin')" @click="emitMenuChange('Groups')">Groups</b-nav-item>
                <b-nav-item v-on:click="emitMenuChange('Workplaces')">Projects</b-nav-item>
                <b-nav-item v-on:click="emitMenuChange('Customize')">Customize GIRI</b-nav-item>
                <b-nav-item v-if="(analyticsStatus || userType === 'admin')" @click="emitMenuChange('Analytics')">Analytics</b-nav-item> 
                <b-nav-item v-on:click="emitMenuChange('OrgaTools')" v-if="userType === 'admin'">Organization</b-nav-item>
                <b-nav-item v-on:click="emitMenuChange('demoTools')" v-if="userType === 'admin'">Demo Accounts</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item disabled>{{email}}</b-nav-item>
                <b-nav-item v-on:click="logout()">Log Out</b-nav-item>
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <stack-modal :show="showLogoutError" title="Error while logging out, please refresh the page" @close="showLogoutError=false">
           Logout error
        </stack-modal>
        </div>
</template>

<script>
    import { EventBus } from '../../main';
    import StackModal from '@innologica/vue-stackable-modal'
    import axios from 'axios'
    export default{
        components:{
            StackModal
        },
        data(){
            return{
                showLogoutError:false,
                saveButtonDefaults:{
                    title: 'Ok',
                    visible: false,
                    btnClass: {'btn btn-primary': true,
                    onClick:"return sampleFunction()"}
                },
                cancelButtonDefaults:{
                    title: 'Ok',
                    visible: true,
                    btnClass: {'btn btn-secondary': true}
                }
            }
        },
        props:{
            userType:String,
            token:String,
            address:String,
            email:String,
            analyticsStatus: Boolean
        },
        methods:{
            logout(){
                console.log("attempted to logout");
                axios.post(this.$address+'api/UserAccounts/logout?access_token='+this.token).then(
                    response=>{
                        console.log(response.data)
                        location.reload();
                    },
	                error => { 
                        console.log("SHOWMODAL logout error")
                        this.showLogoutError=true;
                    }
                ) 
            },
            emitMenuChange(nextTab){
                EventBus.$emit("outsideRefresh","workflows")
                EventBus.$emit("resetToDashboard","dashboard")
                EventBus.$emit("changeTittle","Overview")
                if (nextTab === 'Groups') EventBus.$emit("changeGroupsTab")
                if (nextTab === 'Workplaces') EventBus.$emit("changeWorkplacesTab")
                this.$emit("ChangeMenu",nextTab);
            }
        }
    }
</script>
<style lang="css" scoped>

</style>